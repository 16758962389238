import React, { Component } from 'react'
import ReactModal from 'react-modal'
import { Link } from 'gatsby'

class IndexPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalOpen: false,
    }
  }
  handleModalOpen = event => {
    // console.log('handleModalOpen: ', event);
    this.setState({ isModalOpen: true })
  }

  handleModalClose = event => {
    // console.log('handleModalOpen: ', event);
    this.setState({ isModalOpen: false })
  }

  render() {
    return (
      <div>
          <div onClick={this.handleModalOpen} class="open-modal-btn">
          </div>
        <ReactModal
          isOpen={this.state.isModalOpen}
          onRequestClose={this.handleModalClose}
        >
          <iframe width="560" height="315" src="https://www.youtube.com/embed/YjE1TuJ8nLo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </ReactModal>
      </div>
    )
  }
}

export default IndexPage