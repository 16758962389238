import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import BackgroundImage from "gatsby-background-image";
import { Container, Row, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import Layout from "../components/layout";
import Dotsimg from "../images/dots.inline.svg";
import Handimg from "../images/handimg.inline.svg";
import Graydotsimg from "../images/gray-dots.inline.svg";
import Modal from "../components/modal";
import Accordion from "react-bootstrap/Accordion";

function Homepage({ data, pageContext: { lang } }) {

  const faq_1 = data.wordpressPage.acf.faq_1_content;
  const faq_2 = data.wordpressPage.acf.faq_2_content;
  const faq_3 = data.wordpressPage.acf.faq_3_content;
  const faq_4 = data.wordpressPage.acf.faq_4_content;
  const faq_5 = data.wordpressPage.acf.faq_5_content;
  const faq_6 = data.wordpressPage.acf.faq_6_content;
  const faq_7 = data.wordpressPage.acf.faq_7_content;
  const faq_8 = data.wordpressPage.acf.faq_8_content;

  console.log(faq_1);

  return (
    <Layout
      wpLang={lang}
      pageTitle={data.wordpressPage.yoast_title}
      pageMeta={data.wordpressPage.yoast_meta}
      featuredImagePath="above-min.png"
    >
      <Container className="px-0 fluid">
        <div id="intercompopup">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 32">
            <path d="M28 32s-4.714-1.855-8.527-3.34H3.437C1.54 28.66 0 27.026 0 25.013V3.644C0 1.633 1.54 0 3.437 0h21.125c1.898 0 3.437 1.632 3.437 3.645v18.404H28V32zm-4.139-11.982a.88.88 0 00-1.292-.105c-.03.026-3.015 2.681-8.57 2.681-5.486 0-8.517-2.636-8.571-2.684a.88.88 0 00-1.29.107 1.01 1.01 0 00-.219.708.992.992 0 00.318.664c.142.128 3.537 3.15 9.762 3.15 6.226 0 9.621-3.022 9.763-3.15a.992.992 0 00.317-.664 1.01 1.01 0 00-.218-.707z"></path>
          </svg>
        </div>
        <Row className="hero home-above">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="hero__text-holder">
                  <h1
                    className="hero__title"
                    dangerouslySetInnerHTML={{
                      __html: data.wordpressPage.acf.hero_text,
                    }}
                  />
                  <h2
                    className="hero__subtitle"
                    dangerouslySetInnerHTML={{
                      __html: data.wordpressPage.acf.hero_description,
                    }}
                  />
                  <a
                    className="draw hide-mobile"
                    href="https://app.card.dagcoin.org/signup"
                    dangerouslySetInnerHTML={{
                      __html: data.wordpressPage.acf.order_now_text,
                    }}
                  />
                </div>
                <div className="card">
                  <Img fluid={data.cardpngimg.childImageSharp.fluid} />
                  <Dotsimg className="dots" />
                </div>
                <a
                  className="show-mobile draw"
                  href="https://app.card.dagcoin.org/signup"
                  dangerouslySetInnerHTML={{
                    __html: data.wordpressPage.acf.order_now_text,
                  }}
                />
              </div>
            </div>
          </div>
        </Row>
        <Row id="video-part">
          <div className="video-wrap">
            <div className="video-col">
              <div className="video-play">
                <Img fluid={data.Videoimg.childImageSharp.fluid} />
                <div className="pop-up-video">
                  <Img fluid={data.play.childImageSharp.fluid} />
                </div>
                <Modal />
              </div>
            </div>
            <div className="video-text">
              <h3
                dangerouslySetInnerHTML={{
                  __html: data.wordpressPage.acf.video_title,
                }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: data.wordpressPage.acf.video_description,
                }}
              />
            </div>
          </div>
          <div className="gray-dots">
            <Graydotsimg />
          </div>
        </Row>

        <Row id="for_everyone">
          <BackgroundImage
            fluid={data.Worldimg.childImageSharp.fluid}
            style={{ backgroundSize: "100% auto" }}
          ></BackgroundImage>
          <div className="every-wrap">
            <h3
              dangerouslySetInnerHTML={{
                __html: data.wordpressPage.acf.for_title,
              }}
            />
            <div className="three-col-every">
              <div className="every-list">
                <h4
                  dangerouslySetInnerHTML={{
                    __html: data.wordpressPage.acf.for_1,
                  }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: data.wordpressPage.acf.for_1_content,
                  }}
                />
              </div>
              <div className="every-list">
                <h4
                  dangerouslySetInnerHTML={{
                    __html: data.wordpressPage.acf.for_2,
                  }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: data.wordpressPage.acf.for_2_content,
                  }}
                />
              </div>
              <div className="every-list">
                <h4
                  dangerouslySetInnerHTML={{
                    __html: data.wordpressPage.acf.for_3,
                  }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: data.wordpressPage.acf.for_3_content,
                  }}
                />
              </div>
            </div>
          </div>
        </Row>
        <Row id="faq">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h3
                  dangerouslySetInnerHTML={{
                    __html: data.wordpressPage.acf.faq_title,
                  }}
                />
                <div className="faq-content">
                  <Accordion defaultActiveKey="0">
                    <Card.Header>
                      <Accordion.Toggle eventKey="1" className="custom-toggle">
                        Toggle
                      </Accordion.Toggle>
                      <Accordion.Toggle as={Button} variant="link" eventKey="1">
                        {data.wordpressPage.acf.faq_1_title}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        {" "}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: faq_1,
                          }}
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Accordion>

                  <Accordion>
                    <Card.Header>
                      <Accordion.Toggle eventKey="2" className="custom-toggle">
                        Toggle
                      </Accordion.Toggle>
                      <Accordion.Toggle as={Button} variant="link" eventKey="2">
                        {data.wordpressPage.acf.faq_2_title}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                      <Card.Body>
                        {" "}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: faq_2,
                          }}
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Accordion>

                  <Accordion>
                    <Card.Header>
                      <Accordion.Toggle eventKey="3" className="custom-toggle">
                        Toggle
                      </Accordion.Toggle>
                      <Accordion.Toggle as={Button} variant="link" eventKey="3">
                        {data.wordpressPage.acf.faq_3_title}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="3">
                      <Card.Body>
                        {" "}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: faq_3,
                          }}
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Accordion>

                  <Accordion>
                    <Card.Header>
                      <Accordion.Toggle eventKey="4" className="custom-toggle">
                        Toggle
                      </Accordion.Toggle>
                      <Accordion.Toggle as={Button} variant="link" eventKey="4">
                        {data.wordpressPage.acf.faq_4_title}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="4">
                      <Card.Body>
                        {" "}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: faq_4,
                          }}
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Accordion>

                  <Accordion>
                    <Card.Header>
                      <Accordion.Toggle eventKey="5" className="custom-toggle">
                        Toggle
                      </Accordion.Toggle>
                      <Accordion.Toggle as={Button} variant="link" eventKey="5">
                        {data.wordpressPage.acf.faq_5_title}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="5">
                      <Card.Body>
                        {" "}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: faq_5,
                          }}
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Accordion>

                  <Accordion>
                    <Card.Header>
                      <Accordion.Toggle eventKey="6" className="custom-toggle">
                        Toggle
                      </Accordion.Toggle>
                      <Accordion.Toggle as={Button} variant="link" eventKey="6">
                        {data.wordpressPage.acf.faq_6_title}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="6">
                      <Card.Body>
                        {" "}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: faq_6,
                          }}
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Accordion>

                  <Accordion>
                    <Card.Header>
                      <Accordion.Toggle eventKey="7" className="custom-toggle">
                        Toggle
                      </Accordion.Toggle>
                      <Accordion.Toggle as={Button} variant="link" eventKey="7">
                        {data.wordpressPage.acf.faq_7_title}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="7">
                      <Card.Body>
                        {" "}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: faq_7,
                          }}
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Accordion>

                  <Accordion>
                    <Card.Header>
                      <Accordion.Toggle eventKey="8" className="custom-toggle">
                        Toggle
                      </Accordion.Toggle>
                      <Accordion.Toggle as={Button} variant="link" eventKey="8">
                        {data.wordpressPage.acf.faq_8_title}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="8">
                      <Card.Body>
                        {" "}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: faq_8,
                          }}
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Accordion>

                  <div className="helpcontent">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: data.wordpressPage.acf.help_content,
                      }}
                    />
                  </div>
                </div>

                <p
                  className="support-link"
                  dangerouslySetInnerHTML={{
                    __html:
                      data.wordpressPage.acf
                        .didnt_find_an_answer_go_to_support_page,
                  }}
                />
              </div>
            </div>
          </div>
        </Row>
        <Row id="featured">
          <div className="featured-wrap">
            <h3
              dangerouslySetInnerHTML={{
                __html: data.wordpressPage.acf.features_title,
              }}
            />
            <div className="featured-left-box">
              <div className="featured-items">
                <h4
                  dangerouslySetInnerHTML={{
                    __html: data.wordpressPage.acf.feature_title_1,
                  }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: data.wordpressPage.acf.feature_content_1,
                  }}
                />
              </div>
              <div className="featured-items">
                <h4
                  dangerouslySetInnerHTML={{
                    __html: data.wordpressPage.acf.feature_title_2,
                  }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: data.wordpressPage.acf.feature_content_2,
                  }}
                />
              </div>
            </div>
            <div className="featured-right-box">
              <div className="featured-items">
                <h4
                  dangerouslySetInnerHTML={{
                    __html: data.wordpressPage.acf.feature_title_3,
                  }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: data.wordpressPage.acf.feature_content_3,
                  }}
                />
              </div>
              <div className="featured-items">
                <h4
                  dangerouslySetInnerHTML={{
                    __html: data.wordpressPage.acf.feature_title_4,
                  }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: data.wordpressPage.acf.feature_content_4,
                  }}
                />
              </div>
            </div>
            <div className="featured-card">
              <Img fluid={data.cardpngimg.childImageSharp.fluid} />
            </div>
          </div>
        </Row>
        <Row id="join_now" className="dark-part">
          <div className="hand-img">
            <Handimg />
          </div>

          <div className="col-lg-12">
            <h3
              dangerouslySetInnerHTML={{
                __html: data.wordpressPage.acf.order_title,
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: data.wordpressPage.acf.order_description,
              }}
            />
            <a
              className="drawName"
              href="https://app.card.dagcoin.org/signup"
              dangerouslySetInnerHTML={{
                __html: data.wordpressPage.acf.order_now_text,
              }}
            />
          </div>
        </Row>
      </Container>
    </Layout>
  );
}
export const query = graphql`
  query($lang: String) {
    wordpressPage(
      slug: {eq: "home"}
      wpml_current_locale: {eq: $lang}
    ) {
    acf {
      faq_title
      help_content

      faq_1_title
      faq_2_title
      faq_3_title
      faq_4_title
      faq_5_title
      faq_6_title
      faq_7_title
      faq_8_title

      faq_1_content
      faq_2_content
      faq_3_content
      faq_4_content
      faq_5_content
      faq_6_content
      faq_7_content
      faq_8_content

      feature_content_1
      feature_content_2
      feature_content_3
      feature_content_4
      feature_title_1
      feature_title_2
      feature_title_3
      feature_title_4
      features_title
      for_1
      for_1_content
      for_2
      for_2_content
      for_3
      for_3_content
      for_title
      hero_description
      hero_text
      order_description
      order_now_text
      order_title
      page_title
      video_description
      video_title
    }
      title
      yoast_meta {
        name
        content
        property
      }
      yoast_title
        wpml_current_locale
    }
    Videoimg: file(relativePath: { eq: "video-min.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }


    Worldimg: file(relativePath: { eq: "for_everyone.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    cardpngimg: file(relativePath: { eq: "card_image-min.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    play: file(relativePath: { eq: "play-button.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }


  }
`;
export default Homepage;
